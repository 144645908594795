.wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    padding: 50px calc(10px + 1vw);
    grid-column-gap: 3vw;
    grid-row-gap: 50px;
    justify-content: space-between;
    overflow-x: auto;
}

.item {
    width: 20%;
}


.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.wrapp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 7px 10px;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: white;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
}

.wrapp::-webkit-scrollbar {
    display: none;
}

.scrollBarContainer {
    display: flex;
    overflow: scroll;
}

.scrollBarContainer::-webkit-scrollbar {
    display: none;
}

.icon {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon:hover {
    cursor: pointer;
}

.rightIcon {
    transform: rotate(180deg);
    color: #9d9d9d;
}

@media (max-width: 768px) {
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 30px 15px;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        grid-template-rows: auto;
        padding: 30px 15px;

    }

    .item {
    }

    .icon {
        display: none;
    }
}
