.wrapper {
    width: 100%;
    padding: 5px calc(10px + 1vw) 0;
    scroll-margin-top: 40px;
}

.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3vw;
}

.title {
    font-size: calc(18px + .3vw);
    padding: 10px 0;
    color: #515151;
}

@media (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;
    }

    .wrapper {
        padding-top: 0;
        padding-bottom: 0;
        scroll-margin-top: 30px;

    }

    .title {
        padding: 30px 0 20px;
        font-size: calc(20px + .3vw);
    }

}