.backgroundImage {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    transition: 0.3s;
}

.backgroundImage:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.container {
    width: 100%;
    aspect-ratio: 1/1;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
}

.textContainer {
    width: 100%;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(50px);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    color: white;
}

.title {
    color: white;
    font-size: calc(10px + .5vw);
    text-align: center;
    display: block;
    font-family: 'Roboto', sans-serif;
}

.text {
    text-align: center;
}

@media (max-width: 768px) {
    .backgroundImage {
        border-radius: 40px;
    }

    .textContainer {
        border-radius: 0 0 40px 40px;
    }

    .container {
        border-radius: 40px;
        aspect-ratio: 2/1;
    }

    .backgroundImage:hover {
        transform: none;
    }


    .title {
        color: white;
        font-size: calc(16px + .5vw);
        text-align: center;
        display: block;
        font-family: 'Roboto', sans-serif;
    }
}

/*@media (max-width: 430px) {*/
/*    .container{*/
/*        aspect-ratio: 2/1;*/
/*    }*/

/*}*/