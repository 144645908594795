.wrapper {
    margin: 20px calc(10px + 1vw) 0;
    display: flex;
    justify-content: flex-end;
}

.container {
    width: 84px;
    height: 42px;
    border-radius: 15px;
    border: 1px solid #EBEBEB;
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
    overflow: hidden

}

.icon {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon:hover {
    cursor: pointer;
}

.icon svg {
    width: 20px;
    height: 20px;
}

.listIcon svg {
    height: 15px;
    width: 15px;
}

.active {
    background-color: #ed7043;
    fill: white;
}

.active svg {
    fill: white;
}

@media (min-width: 768px) {
    .wrapper {
        display: none;
    }
}