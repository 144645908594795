/*
// .block-categories
*/
@import '../variables';
@import '../functions';


$local-item-margin: 6px;


.block-categories {}
.block-categories__list {
    display: flex;
    flex-wrap: wrap;
    margin: -$local-item-margin;
}
.block-categories__item {
    margin: $local-item-margin;
    width: calc(33.3333% - #{$local-item-margin * 2});
}


.block-categories--layout--classic {
    @media (min-width: 480px) and (max-width: breakpoint(sm-end)) {
        .block-categories__item {
            width: calc(50% - #{$local-item-margin * 2});
        }
    }
    @media (max-width: 479px) {
        .block-categories__item {
            width: calc(100% - #{$local-item-margin * 2});
        }
    }
}


.block-categories--layout--compact {
    @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
        .block-categories__item {
            width: calc(50% - #{$local-item-margin * 2});
        }
    }
    @media (max-width: breakpoint(sm-end)) {
        .block-categories__item {
            width: calc(100% - #{$local-item-margin * 2});
        }
    }
}


@media (max-width: breakpoint(md-start)) {
    .cart.block {
        .cart-table__column.cart-table__column--total{
            span {
                display: none;
            }
        }
        .cart-table__column--price{
            span {
                display: none;
            };
        }
    }
}
