// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
@import './variables';

.block-slideshow__body {
  position: relative;
}

.partnership-section-join {
  font-size: 30px;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  margin-bottom: 0rem;
}

.partnership-section {
  font-size: 36px;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem !important;
  font-weight: 700;
}

@media (max-width: breakpoint(sm-end)) {
  .partnership-section {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 1.5rem !important;
    font-size: 25px;
  }

  .partnership-section-join {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0.2rem !important;
    font-size: 22px;
  }
}

.slider-arrow {
  position: absolute;
  fill: #dfdfdf;
  z-index: 2;
  top: calc(50% - 15px);
  cursor: pointer;
  width: 30px;
  height: 30px;
  opacity: 0.3;
}

.slider-arrow.brands {
  position: absolute;
  fill: #000000;
  z-index: 2;
  top: calc(50% - 10px);
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0.7;
}

.slider-arrow svg {
  width: inherit;
  height: inherit;
}


.slider-arrow:hover {
  fill: #ffffff;
  top: calc(50% - 14px);;
  width: 29px;
  height: 29px;
  opacity: 0.5;
}

.slider-arrow.brands:hover {
  fill: #000000;
  top: calc(50% - 10px);;
  width: 19px;
  height: 19px;
  opacity: 0.5;
}

.slider-arrow.arrow-left {
  left: 2%;
}

.slider-arrow.arrow-right {
  left: calc(98% - 30px);
}

.slider-arrow.arrow-left.brands {
  left: 0;
}

.slider-arrow.arrow-right.brands {
  left: calc(100% - 20px);
}


.block-features__list {
  border: none;
}

.block-features__item.left {
  padding: 0 10px 0 0 !important;
}

.block-features__item.right {
  padding: 0 0 0 10px !important;
}

.block-features__list {
  border: none;
}

.block-features__item {
  border-radius: 2px;
  cursor: pointer;
}

//550x110
.block-features__item img {
  border: 2px solid #f0f0f0;
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 419px) {
  .block-features__item.left {
    padding: 0 !important;
  }
  .block-features__item.right {
    padding: 0 !important;
  }
}


.block-features__divider {
  background: white !important;
}

.color-bonus {
  color: $bonus-color;
}

.color-discount {
  color: $discount-color;
}

.page-header__breadcrumb {
  display: none;
}

@keyframes fetch-loader-animation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.fetch-loader {
  box-sizing: border-box;
  content: '';
  display: block;
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 3px solid rgba(0, 0, 0, .2);
  border-top-color: rgba(0, 0, 0, .6);

  animation-name: fetch-loader-animation;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.fetch-loader.small {
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 3px solid rgba(0, 0, 0, .2);
  border-top-color: rgba(0, 0, 0, .6);
}

.shop-layout__content {
  position: relative;
}

.widget-filters__item {
  position: relative;
}

.dashboard__orders.card {
  position: relative;
}

.cart__table.cart-table {
  position: relative;
}

.order-history-cart-button {
  background: none;
  border: none;
  outline: none !important;
}

.order-history-cart-button:hover {
  fill: #ff6732;
}

.custom-currency {
  display: inline-block;
  letter-spacing: 1.4px;
}

.discount-currency {
  text-decoration: line-through;
  color: $price-old-color;
  font-size: 14px;
}

.discount-currency.small {
  font-size: 13px;
}

.discount-currency.big {
  font-size: 16px;
}

.block-products__featured {
  .product-card__addtocart,.product__actions-item {
    width: 100%;
  }
}
.block-products__featured{
  max-height: 690px;
}
@media only screen and (max-width: 480px) {

  .products-list__body {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .block-products__body {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }
  .block-products__list-item {
    width: 50% !important;
    margin: 0 !important;
  }

  .products-list__item {
    margin: 0 !important;
    width: 50% !important;

    .product-card__rating {
      margin-top: 30px !important;
    }
  }
  .product-card {
   justify-content: space-between;
  }
  .block-products-carousel__column,.block-products__list, .products-list__body {
    .product-card__actions {
      //padding: 0 19px;
    }
    .input-number__input {
      min-width: initial !important;
    }

    .product-card__buttons {
      flex-direction: column;
    }
    .product-card__prices {
      min-height: 40px !important;
    }
  }
  .block-products-carousel{
    .container {
      .block-products-carousel__slider {
        margin-right: -8px;
        margin-left: -8px;
        .slick-slide  {
          padding: 0 !important;
        }
      }
    }
  }
  .chosen {
    height: 46px !important;
    font-size: 20px !important;
  }
}
.product-card__buttons {
  flex-direction: column;
  margin-top: 10px;

}
.input-radio-label {
  position: relative;
  height: 31px;
  width: 70px;
  margin-left: 4px;
  border: 1px solid #ced4da;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $input-radio-disabled-circle-bg;
  p {
    margin: 0 0 0 4px;
  }
}

.cross1 {
  height: 8px;
  width: 1px;
  transform: rotate(45deg);
  background-color: black;

}
.cross2 {
  height: 8px;
  width: 1px;
  transform: rotate(-45deg);
  background-color: black ;
  margin-left: -1px;

}
.input-radio-label__list {
  span {
    background: transparent !important;
  }
}

.product__actions-item, .product-card__addtocart {
  width: 100%;
}
.product-card__info {
  flex-grow: initial !important;
}

.letterSpacing0 {
  letter-spacing: 0px;
}

.wholePriceRowPreview {
  font-size: calc(16px + .3vw);
  line-height: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: orangered;
}

