.imageWrapper {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
}

.backgroundImage {
    width: 100%;
    aspect-ratio: 1/1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    position: relative;
    display: block;
    top: 0;
    left: 0;
    transition: 0.3s;
}

.backgroundImage:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.container {
    width: 100%;
    position: relative;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.textContainer {
    flex: 1;
    width: 100%;
    z-index: 1;
    position: relative;
    border-radius: 0 0 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #515151;
    font-family: 'Roboto', sans-serif;
    padding: 15px 22px 30px;
    overflow: hidden;
    gap: 0px;
}

.title {
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.1rem;
    height: 2.2rem;
    margin-bottom: 3px;
    font-size: calc(12px + .3vw);
    font-weight: 500;
    cursor: pointer;
}

.wholePrice {
    color: orangered;
    font-size: calc(12px + .3vw);
    text-align: center;
}

.articleId {
    font-size: calc(12px + .3vw);
    font-weight: bold;
}

.priceText {
    font-size: calc(16px + .3vw);
    text-align: center;
    display: none;
    overflow: hidden;
}

.description {
    text-align: center;
    padding-bottom: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    max-lines: 2;
    -webkit-box-orient: vertical;
    /*text-align: left;*/
    width: calc(100%);
    font-size: calc(8px + .3vw);
    overflow: hidden;
    text-overflow: ellipsis;
    /* Limit text to 2 lines */
    -webkit-line-clamp: 2; /* for webkit-based browsers */
    line-clamp: 2; /* for non-webkit browsers */
    /* Set height for consistent line height */
    height: 3em;
    color: #9d9d9d;
}

.priceButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border: none;
    background-color: #ff6633;
    width: 90%;
    font-size: calc(10px + .3vw);
    height: 44px;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -22px;
    z-index: 4;
    color: white;
    font-family: 'Roboto', sans-serif;
}

.price {
    font-size: calc(10px + .3vw);
}

.valuta {
    font-size: .8em;
}

.valutaText {
    font-size: .8em;
    margin-left: 3px;
}

@media (max-width: 768px) {
    .backgroundImage {
        border-radius: 0 0 5vw 5vw;
    }

    .imageWrapper {
        border-radius: 5vw;
    }

    .textContainer {
        border-radius: 0 0 5vw 5vw;
        padding: 3px;
    }

    .wholePrice {
        color: orangered;
        font-size: calc(13px + .3vw);
    }

    .articleId {
        font-size: calc(12px + .3vw);

    }

    .container {
        border-radius: 5vw;
    }

    .title {
        font-weight: 500;
        font-size: calc(13px + .3vw);
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.1rem;
        height: 2.2rem;
        margin-bottom: 5px;
    }

    .priceText {
        font-size: calc(14px + .5vw);
    }

    .priceButton {
        display: none;
    }

    .description {
        display: none;
    }

    .priceText {
        display: block;
    }
}


/*row*/

.containerRow {
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding: 15px 0;
    display: flex;
    font-family: Roboto, sans-serif;
    cursor: pointer;
}


.backgroundImageRow {
    width: 32vw;
    aspect-ratio: 1/1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    position: relative;
    display: block;
    top: 0;
    left: 0;
}

.textContainerRow {
    padding-left: 10px;
    width: calc(100% - 32vw);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.titleRow {
    font-size: calc(14px + .3vw);
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1rem;
    margin-bottom: 5px;
}

.descriptionRow {
    font-size: calc(10px + .3vw);
    color: #9d9d9d;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1rem;
    margin-bottom: 5px;
}

.priceContainerRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10%;
}

.priceRow {
    justify-content: space-between;
    font-size: calc(16px + .3vw);
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 5px;
}

.valutaRow {
    font-size: .8em;
    margin-left: 5px;
}

.wholePriceRow {
    font-size: calc(16px + .3vw);
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: orangered;
}

.articleIdRow {
    font-weight: bold;
}

.fontSize16 {
    font-size: calc(12px + .3vw);
}
