@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.lang {
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /*background-color: white;*/
    background-color: transparent;
    border: none;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    color: white;
}

.lang:focus {
    outline: none;
}
