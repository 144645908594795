@keyframes site-preloader-animation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.site-preloader.active {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999;
  opacity: 1;
}
.site-preloader.active::before {
  box-sizing: border-box;
  content: '';
  display: block;
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 3px solid rgba(0, 0, 0, .2);
  border-top-color: rgba(0, 0, 0, .6);

  animation-name: site-preloader-animation;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.site-preloader{
  transition: opacity .3s;
  opacity: 0;
}