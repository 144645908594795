.wrapp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: white;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
}

.wrapp::-webkit-scrollbar {
    display: none;
}

.scrollBarContainer {
    padding: 7px 10px;
    display: flex;
    overflow: scroll;
}

.scrollBarContainer::-webkit-scrollbar {
    display: none;
}

.icon {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon:hover {
    cursor: pointer;
}

.rightIcon {
    transform: rotate(180deg);
    color: #9d9d9d;
}

@media (max-width: 992px) {
    .icon {
        display: none;
    }
}