/*
// .block-mobile-menu
*/
@import '../variables';
@import '../functions';
@import '../mixins/post-card';

@media (min-width: breakpoint(lg-start)) {
  .block-mobile-menu {
    display: none;
  }
}

@media (min-width: breakpoint(lg-start)) {
  .block-mobile-menu {
    display: none;
  }
}

@media (max-width: 340px) {
  .block-mobile-menu {
    .mobile-links__item-link {
      font-size: 14px;
    }
  }
}

.block-mobile-menu {
  .mobile-links__item-title {
    height: 44px!important;

    .mobile-links__item-link {
      padding: #{(44px - 22px) / 2} 14px!important;
    }
  }

  .mobile-links__item-sub-links {
    li {
      line-height: normal;
    }
    .mobile-links__item-link {
      padding: #{(44px - 22px) / 2} 20px!important;
    }
  }
}

.block-mobile-menu-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #ff6633;
  text-decoration: underline;
}
