@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.container {
    padding: 3px 15px;
    border-radius: 8px;
    margin: 0 5px;
    white-space: nowrap;
    color: rgb(157, 157, 157);
    font-weight: 500;
    font-size: calc(12px + .3vw);
    font-family: 'Roboto', sans-serif;
}

.container:hover {
    cursor: pointer;
}

.active {
    background-color: #ff663330;
    color: #ff6633;
}

@media (max-width: 990px) {
    .container {
        font-size: calc(16px + .3vw);
    }
}